.svg {
    max-width: 100%;
}

.preloader {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}