@font-face {
  font-family: "Montserrat-Italic";
  src: url("../asstes/fonts/Montserrat/Montserrat-Italic.ttf");
  src: local("../asstes/fonts/Montserrat/Montserrat-Italic"),
    local("../asstes/fonts/Montserrat/Montserrat-Italic"),
    url("../asstes/fonts/Montserrat/Montserrat-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat-BoldItalic";
  src: url("../asstes/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf");
  src: local("../asstes/fonts/Montserrat/Montserrat-ExtraBoldItalic"),
    local("../asstes/fonts/Montserrat/Montserrat-ExtraBoldItalic"),
    url("../asstes/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf")
      format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("../asstes/fonts/Montserrat/Montserrat-Bold.ttf");
  src: local("../asstes/fonts/Montserrat/Montserrat-Bold"),
    local("../asstes/fonts/Montserrat/Montserrat-Bold"),
    url("../asstes/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("../asstes/fonts/Montserrat/Montserrat-Medium.ttf");
  src: local("../asstes/fonts/Montserrat/Montserrat-Medium"),
    local("../asstes/fonts/Montserrat/Montserrat-Medium"),
    url("../asstes/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url("../asstes/fonts/Montserrat/Montserrat-ExtraBold.ttf");
  src: local("../asstes/fonts/Montserrat/Montserrat-ExtraBold"),
    url("../asstes/fonts/Montserrat/Montserrat-ExtraBold.ttf")
      format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("../asstes/fonts/Montserrat/Montserrat-Regular.ttf");
  src: local("../asstes/fonts/Montserrat/Montserrat-Regular"),
    local("../asstes/fonts/Montserrat/Montserrat-Regular"),
    url("../asstes/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../asstes/fonts/Montserrat/Montserrat-SemiBold.ttf");
  src: local("../asstes/fonts/Montserrat/Montserrat-SemiBold"),
    local("../asstes/fonts/Montserrat/Montserrat-SemiBold"),
    url("../asstes/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Montserrat-SemiBold", "Montserrat-Regular",
    "Montserrat-ExtraBold", "Montserrat-Medium", "Montserrat-Bold",
    "Montserrat-BoldItalic", "Montserrat-Italic";
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

ul,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

li {
  padding: 0;
  list-style-type: none;
}

body [class*="jivoMobileButton"] {
  margin-bottom: 126px;
  margin-right: 28px;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
}

.container_large {
  max-width: 1920px;
  margin: 0 auto;
}

input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active {
  background-clip: text !important;
}

.errorText {
  color: var(--COLOR_RED_NEW);
  text-align: left;
  padding-top: 8px;
}

.fieldWrapper {
  position: relative;
  padding-top: 10px;
  width: 100%;
}

.fieldName {
  position: absolute;
  top: 0;
  left: 24px;
  z-index: 1;
  padding: 0 4px;
  color: var(--COLOR_BLUE_LIGHT);
  font-size: var(--FONT_SIZE_16);
  line-height: 100% !important;
  letter-spacing: 0.16px;
  background-color: var(--COLOR_WHITE);
  border-radius: 4px;
}

.no-scroll {
  overflow: hidden;
}

/* body [class*='om-embedded-campaign om-embedded-campaign-v2']{
  width: 818px;
} */

@media all and (min-width: 1500px) {
  .container {
    padding: 0;
  }
}

/* @media all and (min-width: 992px) {
  body [class*='om-embedded-campaign om-embedded-campaign-v2']{
    width: 327px;
  }
} */