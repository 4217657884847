:root {
  /* ------- COLORS ------- */
  --COLOR_BLACK: #000000;
  --COLOR_BLACK_LIGHT: #1c1c28;
  --COLOR_GRAY: #e4e4e4;
  --COLOR_GRAY_LIGHT: #f8f8f8;
  --COLOR_GRAY_DARK: #353643;
  --COLOR_BLACK_MOBILE: "#1a1e20";
  --COLOR_GRAY_DARKER: #f1f1f1;
  --COLOR_BLUE_LIGHT: #87a5c0;
  --COLOR_WHITE_LIGHT: #f2f5f8;
  --COLOR_WHITE: #ffffff;
  --DARK_WHITE: #f8f8f8;
  --COLOR_GRAY_FONT: #a9a9a9;
  --COLOR_MODAL_SHADOW: hsla(0, 0%, 76.9%, 0.76);
  --COLOR_BLACK_LIGHT: #383744;
  --COLOR_BLUE_LIGHT_BACKGROUND: #e4e4e452;

  /* ------- GREEN ------- */
  --COLOR_GREEN: #3dc383;
  --COLOR_GREEN_HOVER: #2c8f5f;
  --COLOR_GREEN_CLICKED: #1c5c3d;
  --COLOR_GREEN_FONT: #44c789;
  --COLOR_GREEN_LIGHT: #3dc3831a;
  --COLOR_GREEN_FONT_LIGHT: #e9ffeb;
  --COLOR_GREEN_SUBMIT: rgba(61, 195, 131, 1);
  /* ------- RED ------- */
  --COLOR_RED: #f22f2f;
  --COLOR_RED_HOVER: #bf2424;
  --COLOR_RED_CLICKED: #8c1b1b;
  --COLOR_RED_LIGHT: #fae4dc;
  --COLOR_RED_DARK: #ef1212;
  --COLOR_PINK_LIGHT:rgba(242, 223, 222, 1);
  --COLOR_RED_FAIL: #980000;
  --COLOR_RED_BG: #dd1c1af0;
  --COLOR_RED_LIGHT_BG: rgba(212, 23, 21, 1);
  --COLOR_BAGDE_BACKGROUND:rgba(255, 73, 4, 0.2);

  /* ------- BROWN ------- */
  --COLOR_BROWN: #ff8f62;
  --COLOR_BROWN_HOVER: #cc714e;
  --COLOR_BROWN_CLICKED: #99553a;

  /* ------- ORANGE ------- */
  --COLOR_ORANGE_DARK: #ff4904;
  --COLOR_ORANGE: #ff8052;
  --COLOR_ORANGE_LIGHT: #ffb89e;
  --COLOR_BROWN: #721637;

  /* ------- GOLD ------- */
  --COLOR_GOLD_DARK: #ffbb0a;
  --COLOR_GOLD: #ffcf57;
  --COLOR_GOLD_LIGHT: #ffe5a3;
  --COLOR_GOLD_INFO: rgba(255, 229, 163, 1);
  --COLOR_BANNER_TEXT: rgba(255, 215, 73, 1);

  /* ------- PURPLE ------- */
  --COLOR_PURPLE_DARK: #4d0099;
  --COLOR_PURPLE: #824cb7;
  --COLOR_PURPLE_LIGHT: #ffe6ff;
  --COLOR_BLACK_LIGHT: rgba(56, 55, 68, 1);
  --COLOR_PURPLE_FONT:#3F3356;

  /* ------- BLUE ------- */
  --DARK_BLUE: #1c2bea;

  /* ------- ELEMENT SIZES ------- */
  --ROOT_HEIGHT: 100vh;
  --ROOT_WIDTH: 100vw;

  /* Input styles */
  --INPUT_WIDTH: 17.4vw;

  /* Padding styles */
  --ROOT_PADDING: 1rem;

  /* Font size styles */
  --ROOT_FONT_SIZE: 1rem;
  --ICON_FONT_SIZE: 0.875rem;
  --ICON_FONT_SIZE_BIG: 1rem;

  /* -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */

  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */

  /* ------- FONT SIZES ------- */
  --FONT_SIZE_8: 8px;
  --FONT_SIZE_10: 10px;
  --FONT_SIZE_12: 12px;
  --FONT_SIZE_14: 14px;
  --FONT_SIZE_16: 16px;
  --FONT_SIZE_18: 18px;
  --FONT_SIZE_20: 20px;
  --FONT_SIZE_22: 22px;
  --FONT_SIZE_23: 23px;
  --FONT_SIZE_24: 24px;
  --FONT_SIZE_26: 26px;
  --FONT_SIZE_28: 28px;
  --FONT_SIZE_30: 30px;
  --FONT_SIZE_32: 32px;
  --FONT_SIZE_33: 33px;
  --FONT_SIZE_34: 34px;
  --FONT_SIZE_36: 36px;
  --FONT_SIZE_38: 38px;
  --FONT_SIZE_40: 40px;
  --FONT_SIZE_42: 42px;
  --FONT_SIZE_44: 44px;
  --FONT_SIZE_48: 48px;
  --FONT_SIZE_50: 50px;
  --FONT_SIZE_62: 62px;
  --FONT_SIZE_64: 64px;
  --FONT_SIZE_70: 70px;
  --FONT_SIZE_72: 72px;
  --FONT_SIZE_80: 80px;
  --FONT_SIZE_140: 140px;

  /* 0.8rem */
  /* 0.85rem */
  /* 0.875rem */
  /* 1rem */
  /* 1.2rem */
  /* 1.3rem */
  /* 1.5rem */
  /* 2rem */
  /* 2.5rem */

  /* 90% */

  /* 20px */

  /* ------- LINE HEIGHT ------- */
  --LINE_HEIGHT_20: 20px;
  --LINE_HEIGHT_24: 24px;
  --LINE_HEIGHT_120: 120%;
  --LINE_HEIGHT_150: 150%;
  /* 1.5715 */
  /* 1.6 */
  /* 1.8 */

  /* -----BOX SHADOW ----- */
  --BOX_SHADOW: 0px 13px 40px #0000000f;
}

/* ------- FONT WEIGHTS -------
  --FONT_THIN: 200;
  --FONT_LIGHT: 300;
  --FONT_NORMAL: 400;
  --FONT_MEDIUM: 500;
  --FONT_BOLD: 700;
  --FONT_BOLDER: 800;
  --FONT_MID_BOLD:600; */
